<template>
<div>
     <div
        class="collectionlist"
        v-if="articleSameType.preArticle || articleSameType.nextArticle"
      >
        <div
          style="font-size: 15px; color: rgba(0, 0, 0, 0.5); line-height: 20px"
        >
          收录于分类
        </div>

        <div class="collectionbox">
          <div class="collectionitem" @click="HandlerShowSameTypeArticleList()">
            <van-tag color="rgb(255 242 219)" text-color="#9d1d22" size="large"
              >#{{ articleSameType.sameType.name }}# </van-tag
            ><span class="collectioncount">{{
              articleSameType.sameType.count
            }}</span>
          </div>
        </div>
        <div style="padding: 10px 0px">
          <van-row
            v-if="articleSameType.preArticle && articleSameType.nextArticle"
          >
            <van-col span="11" @click="toDetail(articleSameType.preArticle.id)">
              <div>
                <div style="line-height: 18px">
                  <i class="van-icon van-icon-arrow-left van-nav-bar__arrow"
                    ><!----></i
                  >
                  上一篇
                </div>
                <div style="font-size: 16px">
                  {{ articleSameType.preArticle.title }}
                </div>
              </div>
            </van-col>
            <van-col span="2"></van-col>
            <van-col
              span="11"
              @click="toDetail(articleSameType.nextArticle.id)"
            >
              <div style="text-align: right">
                下一篇
                <i class="van-icon van-icon-arrow van-cell__right-icon"
                  ><!----></i
                >
              </div>
              <div style="font-size: 16px">
                {{ articleSameType.nextArticle.title }}
              </div>
            </van-col>
          </van-row>
          <van-row v-else-if="articleSameType.preArticle">
            <van-col span="2"></van-col>
            <van-col span="22" @click="toDetail(articleSameType.preArticle.id)">
              <div>
                <div style="line-height: 18px">
                  <i class="van-icon van-icon-arrow-left van-nav-bar__arrow"
                    ><!----></i
                  >
                  上一篇
                </div>
                <div style="font-size: 16px">
                  {{ articleSameType.preArticle.title }}
                </div>
              </div>
            </van-col>
          </van-row>
          <van-row v-else-if="articleSameType.nextArticle">
            <van-col span="2"></van-col>
            <van-col
              span="22"
              @click="toDetail(articleSameType.nextArticle.id)"
            >
              <div>
                <div>
                  下一篇
                  <i class="van-icon van-icon-arrow van-cell__right-icon"
                    ><!----></i
                  >
                </div>
                <div style="font-size: 16px">
                  {{ articleSameType.nextArticle.title }}
                </div>
              </div>
            </van-col>
          </van-row>
        </div>
      </div>
      <van-popup
        v-model="showSameTypeArticleList"
        closeable
        position="bottom"
        :style="{ height: '90%' }"
      >
        <div style="padding: 50px 10px 20px 10px">
          <van-list
            v-model="upLoading"
            :finished="finished"
            @load="productonLoad"
            finished-text="没有更多了"
          >
            <van-cell-group>
              <van-cell
                v-for="item in sameTypeArticleList"
                :key="item.id"
                @click="toDetail(item.id.toString())"
                is-link
              >
                <template #title>
                  <span class="custom-title">{{ item.title }}</span>
                </template>
              </van-cell>
            </van-cell-group>
          </van-list>
        </div>
      </van-popup>
</div>
</template>


<script>
import {  getArticleList,getArticleSameTypeShow } from "@/api/article";

export default {
  name: "sameTypeArticle",
  props: {
    article: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
     
        articleSameType: {},
        showSameTypeArticleList: false,
        sameTypeArticleList: [],

        upLoading: false,
        finished: false,
        articleList: [],
        page: {
          sorterType: 0,
          current: 1,
          pageSize: 10,
          filterData: {
            subTypeIds: "",
            title: "",
            typeId: 100,
            productId: "00000000-0000-0000-0000-000000000000",
          },
          fromType:1,
        },
    };
  },
  components: {
  },
  updated() {
  
  },
  created() {
    this.init();
  },
  watch: {
  },
  mounted() {},

  methods: {
   async init(){
      let aresult =  await getArticleSameTypeShow(this.article.id)
      this.articleSameType=aresult.data.data;
      this.page.filterData.typeId=this.article.typeId;
      this.page.filterData.subTypeIds = this.article.subTypeIds;
    },
    toDetail(id) {
      this.$router.push({
        path: "/article/detail",
        query: { para: id,type: this.article.typeId  },
      });
    },

    async HandlerShowSameTypeArticleList() {
      this.showSameTypeArticleList = true;
    },
    async productonLoad() {
      if(this.page.current==1){
          this.sameTypeArticleList = [];
      }
      let aresult = await getArticleList({ ...this.page });
      this.sameTypeArticleList.push(...aresult.data.data);
      this.upLoading = false;
      if (
        this.page.current * this.page.pageSize <
        aresult.data.total
      ) {
        this.page.current++;
      } else {
        this.finished = true;
      }
    },
  },
};
</script>


<style lang="less" scoped>

.collectionlist {
  margin: 0 0 16px;
  padding: 12px 16px;
  border-radius: 8px;
  background-color: #f7f7f7;
}
.collectionbox {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.collectionitem {
  margin-right: 12px;
  margin-top: 6px;
  position: relative;
  display: -ms-flexbox;
  display: flex;
}
.collectioncount {
  display: inline-block;
  color: rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  font-size: 15px;
  line-height: 20px;
  margin-left: 4px;
}
</style>